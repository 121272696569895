import React, { useEffect } from "react"
import { SEO as Seo } from "../components/SEO.js"
import CareersContent from "../components/CareersContent"
import CareersHeader from "../components/CareersHeader"
import Layout from "../components/Layout"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"
import { Button } from "../components/Button.js"
import { graphql } from "gatsby"
import AOS from "aos"
import "aos/dist/aos.css"
import {
  RobotoMedium,
  RobotoNormal,
  RobotoNormal2,
  MPAccent,
} from "../styles/PageStyles.js"

export default function Careers({ data }) {
  useEffect(() => {
    AOS.init({ duration: 1000 })
  }, [])
  const careers = data.allMarkdownRemark.nodes

  const CareerBoxStyle = {
    background: "rgb(8, 30, 53)",
    borderBottomRightRadius: "30px",
    height: "100%",
    width: "100%",
    padding: "40px",
  }

  const CareerRowStyle = {
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  }

  return (
    <Layout>
      <Seo
        title="Blackfort - Careers"
        description="Part of our culture is continuous improvement, so if you are ready to be a part of an ever-evolving, dynamic and fun team, then sign up to join us!"
        url="https://www.blackfort.ph/careers"
      />
      <CareersHeader />
      <StyledContainer fluid>
        <StyledRow>
          <Col lg={12} style={{ textAlign: "center", paddingBottom: "10vh" }}>
            <RobotoMedium>We Need Great People</RobotoMedium>
            <Col style={{ paddingTop: 30 }}>
              <RobotoNormal>
                Your great talents deserve an even greater career, and it starts
                here:
              </RobotoNormal>
            </Col>
          </Col>

          <Row style={{margin: 0}}>
            {careers.map(career => (
              <StyledCol lg={6}>
                <div style={CareerBoxStyle}>
                  <Row style={CareerRowStyle}>
                    <Col lg={8}>
                      <MPAccent style={{ paddingBottom: 24 }}>
                        {career.frontmatter.title}
                      </MPAccent>
                      <RobotoNormal2>
                        {career.frontmatter.description}
                      </RobotoNormal2>
                    </Col>
                    <Col>
                      <Button
                        round
                        primary
                        to={career.fields.slug}
                        key={career.id}
                      >
                        <RobotoNormal2 className="btn-text">
                          Apply
                        </RobotoNormal2>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </StyledCol>
            ))}
          </Row>
        </StyledRow>
      </StyledContainer>
      <CareersContent />
    </Layout>
  )
}

// styled components

const StyledContainer = styled(Container)`
  padding: 10vh 0 0 0;
`

const StyledRow = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10% 0 10%;

  @media screen and (max-width: 768px) {
    padding: 0 5vw 0 5vw;
  }
`

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px;

  @media screen and (max-width: 768px) {
    height: 100%;
    flex-direction: column;
    text-align: center;
    a {
      margin-top: 30px;
    }
  }
`

// export page query

export const query = graphql`
  query CareersPage {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "careers-page" } }}
      sort: {fields: frontmatter___title}
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          description
        }
        html
        id
      }
    }
  }
`
