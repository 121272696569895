import React, { useEffect, useState } from "react"
import { Row, Col } from "react-bootstrap"
import { Spring } from "react-spring/renderprops"
import { easePolyOut } from "d3-ease"
import { StaticImage } from "gatsby-plugin-image"
import {
  StyledContainer,
  Header,
  Div,
  Description,
  PageTitle,
  Border,
  StyledCol,
} from "../styles/HeaderStyles"

const CareersHeader = () => {
  // PARALLAX EFFECT
  const [offsetY, setOffsetY] = useState(0)
  const handleScroll = () => setOffsetY(window.pageYOffset)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <StyledContainer fluid>
      <Header>
        <StaticImage
          src="./multimedia/career-banner.jpg"
          alt="Careers"
          className="headerBg"
          imgStyle={{ transform: `translateY(${offsetY * 0.8}px)` }} // PARALLAX EFFECT
        />
      </Header>
      <Div>
        <Description>
          <Spring
            from={{ opacity: 0, marginLeft: -50 }}
            to={{ opacity: 1, marginLeft: 0 }}
            config={{ duration: 800, easing: easePolyOut }}
          >
            {props => (
              <div style={props}>
                <PageTitle lg={12} sm={12}>
                  Careers
                </PageTitle>
              </div>
            )}
          </Spring>
          <Spring
            from={{ opacity: 0, marginLeft: -50 }}
            to={{ opacity: 1, marginLeft: 0 }}
            config={{
              easing: easePolyOut,
              delay: 400,
              duration: 800,
              tension: 120,
              friction: 14,
            }}
          >
            {props => (
              <div style={props}>
                <Border lg={12}>
                  <Row>
                    <StyledCol
                      lg={2}
                      xs={0}
                      md={0}
                      style={{ justifyContent: "center" }}
                    >
                      <StaticImage
                        src="./multimedia/b-logo.png"
                        alt="Blackfort Logo"
                        className="logo"
                        placeholder="none"
                        backgroundColor="#FFB700"
                      />
                    </StyledCol>
                    <StyledCol lg={10} xs={12} md={12}>
                      <Col lg={12}>
                        <span>
                          Part of our culture is continuous improvement, so if
                          you are ready to be a part of an ever-evolving,
                          dynamic and fun team, then sign up to join us!
                        </span>
                      </Col>
                    </StyledCol>
                  </Row>
                </Border>
              </div>
            )}
          </Spring>
        </Description>
      </Div>
    </StyledContainer>
  )
}

export default CareersHeader
