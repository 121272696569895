import React from "react"
import styled from "styled-components"
import { Col, Container, Image, Row } from "react-bootstrap"
import salary from "./multimedia/salary.svg"
import insurance from "./multimedia/shield.svg"
import bonus from "./multimedia/bonus.svg"
import group from "./multimedia/group.svg"
import laptop from "./multimedia/laptop.svg"
import annualLeave from "./multimedia/luggage.svg"
import Carousel from "react-elastic-carousel"
import { StaticImage } from "gatsby-plugin-image"
import { RobotoMedium, RobotoNormal2 } from "../styles/PageStyles.js"

const CareersContent = () => {
  const breakPoints = [
    { width: 500, itemsToShow: 1 },
    { width: 768, itemsToShow: 2 },
    { width: 1024, itemsToShow: 3 },
  ]

  return (
    <Container fluid>
      <StyledRow>
        <StyledCol lg={12} justifyCenter alignCenter>
          <RobotoMedium>Why You'll Love Working Here</RobotoMedium>
        </StyledCol>
        <StyledCol lineBreak>
          <StyledRow>
            <StyledCol
              lg={5}
              justifyCenter
              alignCenter
              margin
              height
              columnReversed_M
            >
              <StyledCol
                lg={9}
                xs={12}
                column
                justifyCenter
                isPadded
                alignRight
                data-aos="fade-right"
                data-aos-delay="500"
                data-aos-duration="500"
              >
                <RobotoNormal2>
                  Attractive salary and annual salary review
                </RobotoNormal2>
              </StyledCol>
              <StyledCol
                lg={3}
                xs={8}
                justifyCenter
                alignCenter
                bg
                isPadded
                height
                data-aos="flip-left"
                data-aos-delay="200"
                data-aos-duration="500"
              >
                <StyledImage
                  src={salary}
                  alt="Attractive salary and annual salary review"
                />
              </StyledCol>
            </StyledCol>
            <StyledCol lg={5} justifyCenter alignCenter margin height>
              <StyledCol
                lg={3}
                xs={8}
                justifyCenter
                alignCenter
                bg
                isPadded
                height
                data-aos="flip-right"
                data-aos-delay="200"
                data-aos-duration="500"
              >
                <StyledImage
                  src={group}
                  alt="Working in a professional, friendly, well-equipped environment with continuous learning as core value"
                />
              </StyledCol>
              <StyledCol
                lg={9}
                column
                justifyCenter
                isPadded
                data-aos="fade-left"
                data-aos-delay="500"
                data-aos-duration="500"
              >
                <RobotoNormal2>
                  Working in a professional, friendly, well-equipped environment
                  with continuous learning as core value
                </RobotoNormal2>
              </StyledCol>
            </StyledCol>
            <StyledCol
              lg={5}
              justifyCenter
              alignCenter
              margin
              height
              columnReversed_M
            >
              <StyledCol
                lg={9}
                column
                justifyCenter
                isPadded
                alignRight
                data-aos="fade-right"
                data-aos-delay="500"
                data-aos-duration="500"
              >
                <RobotoNormal2>
                  Health benefits and insurance, according to Philippine labour
                  law
                </RobotoNormal2>
              </StyledCol>
              <StyledCol
                lg={3}
                xs={8}
                justifyCenter
                alignCenter
                bg
                isPadded
                height
                data-aos="flip-left"
                data-aos-delay="200"
                data-aos-duration="500"
              >
                <StyledImage
                  src={insurance}
                  alt="Health benefits and insurance, according to Philippine labour law"
                />
              </StyledCol>
            </StyledCol>
            <StyledCol lg={5} justifyCenter alignCenter margin height>
              <StyledCol
                lg={3}
                xs={8}
                justifyCenter
                alignCenter
                bg
                isPadded
                height
                data-aos="flip-right"
                data-aos-delay="200"
                data-aos-duration="500"
              >
                <StyledImage src={laptop} alt="Gaming laptop for work" />
              </StyledCol>
              <StyledCol
                lg={9}
                column
                justifyCenter
                isPadded
                data-aos="fade-left"
                data-aos-delay="500"
                data-aos-duration="500"
              >
                <RobotoNormal2>Gaming Laptop for Work</RobotoNormal2>
              </StyledCol>
            </StyledCol>
            <StyledCol
              lg={5}
              justifyCenter
              alignCenter
              margin
              height
              columnReversed_M
            >
              <StyledCol
                lg={9}
                column
                justifyCenter
                isPadded
                alignRight
                data-aos="fade-right"
                data-aos-delay="500"
                data-aos-duration="500"
              >
                <RobotoNormal2>
                  13th Month salary and bonus based on performance
                </RobotoNormal2>
              </StyledCol>
              <StyledCol
                lg={3}
                xs={8}
                justifyCenter
                alignCenter
                bg
                isPadded
                height
                data-aos="flip-left"
                data-aos-delay="200"
                data-aos-duration="500"
              >
                <StyledImage
                  src={bonus}
                  alt="13th Month salary and bonus based on performance"
                />
              </StyledCol>
            </StyledCol>
            <StyledCol lg={5} justifyCenter alignCenter margin height>
              <StyledCol
                lg={3}
                xs={8}
                justifyCenter
                alignCenter
                bg
                isPadded
                height
                data-aos="flip-right"
                data-aos-delay="200"
                data-aos-duration="500"
              >
                <StyledImage
                  src={annualLeave}
                  alt="15 days annual leave per year"
                />
              </StyledCol>
              <StyledCol
                lg={9}
                column
                justifyCenter
                isPadded
                data-aos="fade-left"
                data-aos-delay="500"
                data-aos-duration="500"
              >
                <RobotoNormal2>15 days annual leave per year</RobotoNormal2>
              </StyledCol>
            </StyledCol>
          </StyledRow>
        </StyledCol>
        <StyledCarousel
          breakPoints={breakPoints}
          enableAutoPlay
          autoPlaySpeed={5000}
        >
          <CarouselItem>
            <StaticImage
              className="CarouselImage"
              draggable="false"
              src="./multimedia/Business-Dinner-2.jpg"
              alt="Career 1"
            />
            <Overlay>
              <RobotoNormal2>
                Exchanging ideas over a good meal always gets the best results!
              </RobotoNormal2>
            </Overlay>
          </CarouselItem>
          <CarouselItem>
            <StaticImage
              className="CarouselImage"
              draggable="false"
              src="./multimedia/Contract-Renewal-2.jpg"
              alt="Career 2"
            />
            <Overlay>
              <RobotoNormal2>
                Here at Blackfort, we value each client as an important part of
                our family and network. Each team member is recognized for their
                efforts as we celebrate another contract renewed!
              </RobotoNormal2>
            </Overlay>
          </CarouselItem>
          <CarouselItem>
            <StaticImage
              className="CarouselImage"
              draggable="false"
              src="./multimedia/Contract-Renewal-1.jpg"
              alt="Career 3"
            />
            <Overlay>
              <RobotoNormal2>
                Their smiles reflect the dedication and hardwork we bring into
                each of our projects, and we are proud to say that all of our
                clients are extremely satisfied with the results we provide!
              </RobotoNormal2>
            </Overlay>
          </CarouselItem>
          <CarouselItem>
            <StaticImage
              className="CarouselImage"
              draggable="false"
              src="./multimedia/Team-Lunch.jpg"
              alt="Career 4"
            />
            <Overlay>
              <RobotoNormal2>
                Here at Blackfort, we always make time for a little R&R, even if
                it's just over lunch!
              </RobotoNormal2>
            </Overlay>
          </CarouselItem>
          <CarouselItem>
            <StaticImage
              className="CarouselImage"
              draggable="false"
              src="./multimedia/Business-Dinner.jpg"
              alt="Career 5"
            />
            <Overlay>
              <RobotoNormal2>
                Another successful business planning, made more memorable with
                having dinner as one solid team!
              </RobotoNormal2>
            </Overlay>
          </CarouselItem>
          <CarouselItem>
            <StaticImage
              className="CarouselImage"
              draggable="false"
              src="./multimedia/Year-End-Party.jpg"
              alt="Career 6"
            />
            <Overlay>
              <RobotoNormal2>
                Here we are, celebrating the year's milestones and sending off
                good vibes as we welcome the incoming year together!
              </RobotoNormal2>
            </Overlay>
          </CarouselItem>
        </StyledCarousel>
      </StyledRow>
    </Container>
  )
}

export default CareersContent

const StyledRow = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10vh 5% 0 5%;

  @media screen and (max-width: 768px) {
    padding: 10vh 3vw 0 3vw;
  }
`

const StyledCol = styled(Col)`
  display: flex;
  height: ${({ height }) => (height ? "125px" : "auto")};
  justify-content: ${({ justifyCenter }) =>
    justifyCenter ? "center" : undefined};
  align-items: ${({ alignCenter }) => (alignCenter ? "center" : undefined)};
  flex-direction: ${({ column }) => (column ? "column" : undefined)};
  background: ${({ bg }) => (bg ? "#081e35" : undefined)};
  padding: ${({ isPadded }) => (isPadded ? "25px !important" : "0")};
  margin: ${({ margin }) => (margin ? "15px" : undefined)};
  text-align: ${({ alignRight }) => (alignRight ? "right" : "left")};
  padding-bottom: ${({ lineBreak }) => (lineBreak ? "10vh" : undefined)};

  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
    flex-direction: ${({ columnReversed_M }) =>
      columnReversed_M ? "column-reverse" : "column"};
    text-align: center;
    height: auto;
  }
`

const StyledImage = styled(Image)`
  width: 75px;
`

const Overlay = styled.div`
  position: absolute;
  width: 12%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.5s ease-in-out;
  transform: translateY(20px);

  p {
    color: black;
  }
`

const CarouselItem = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffb700;

  .CarouselImage {
    transition: 0.5s ease-in-out;
  }

  &:hover {
    ${Overlay} {
      opacity: 100%;
      transform: translateY(0px);
    }
    .CarouselImage {
      opacity: 10%;
    }
  }

  @media screen and (max-width: 768px) {
    margin: 0;
  }
`

const StyledCarousel = styled(Carousel)`
  .rec-arrow {
    display: none;
  }

  .rec-dot {
    background: #333;
    box-shadow: none;
  }

  .rec-dot_active {
    background: #ffb700;
  }
`
