import styled from "styled-components"
import { Container, Col } from "react-bootstrap"

export const StyledContainer = styled(Container)`
  padding-right: 0;
  padding-left: 0;

  * {
    color: black;
  }
`

export const Header = styled.div`
  height: 85vh;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(46, 49, 49, 0.3);

  @media screen and (max-width: 1024px) {
    height: 60vh;
  }

  & .headerBg {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: -1;

    @media screen and (max-width: 1024px) {
      object-fit: cover;
      object-position: center top;
    }
  }
`

export const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 16px;
  margin-top: -20vh;
  padding-left: 10%;
  padding-right: 10%;
  top: 80vh;
  z-index: 1;

  @media screen and (max-width: 768px) {
    width: 90%;
    padding: 0;
  }
`

export const PageTitle = styled.h1`
  position: relative;
  font-family: Maven Pro;
  font-weight: 700;
  font-style: normal;
  font-size: 48px;
  line-height: 56px;
  color: #ffb700;
  
  left: 4vw;
  bottom: 25px;
  

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
`

export const Border = styled.div`
  width: 100%;
  border-bottom-right-radius: 30px;
  background: #ffb700;
  padding: 20px;
  font-size: 1em;
  font-weight: 400;
  color: black;

  @media screen and (max-width: 768px) {
    font-size: 2em;
    bottom: 20%;
  }

  @media screen and (max-width: 540px) {
    font-size: 1em;
    bottom: 30%;
  }
`

export const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;

  span {
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
  }

  & .logo {
    @media screen and (max-width: 768px) {
      height: 0;
    }
  }
`